import styles from "./ToggleSwitch.module.css";
import { updateInventory } from "../../../../env/APIManager";
import ClipLoader from "react-spinners/ClipLoader";
import { useFetch } from "../../../../useFetch";

const ToggleSwitch = ({ handleToggle, toggle, id, index }) => {
  const { fetchFuntion, fetchLoading } = useFetch();

  const handleSwitchToggle = async () => {
    let arg = {
      url: updateInventory,
      method: "PATCH",
      data: {
        sku: { skuId: id, inStock: !toggle },
      },
      auth: true,
    };

    const res = await fetchFuntion(arg);

    if (res?.code === "SUCCESS") handleToggle(id, index, !toggle);
  };

  return (
    <div className={styles["toggle-switch-container"]}>
      {fetchLoading ? (
        <div className={styles["spinner"]}>
          <ClipLoader size={20} color="#000" />
        </div>
      ) : (
        <></>
      )}
      <input
        className={styles["privacy-toggle"]}
        id={"toggle-for-checkbox" + index}
        type="checkbox"
        checked={toggle}
        onChange={handleSwitchToggle}
      />
    </div>
  );
};

export default ToggleSwitch;
