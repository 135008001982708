import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { LOGOUT_USER } from "./store/user";

export function useFetch(arg) {
  let token =
    localStorage.getItem("token") === null ||
    localStorage.getItem("token") === undefined ||
    localStorage.getItem("token") === ""
      ? ""
      : JSON.parse(localStorage.getItem("token"));
  const { url, method, data, auth } = arg ?? {};
  const dispatch = useDispatch();

  const navigate = useNavigate();
  // to store the API response
  const [result, setResult] = useState([]);
  // to control  spinner on page load
  const [loading, setLoading] = useState(false);
  // to control  spinner when fetchFuntion is used
  const [fetchLoading, setFetchLoading] = useState(false);

  // to create action based on the API response, reusable function
  function checkAPIResponse(data) {
    if (data?.code === "SUCCESS") {
      setLoading(false);
      setFetchLoading(false);
      setResult(data);
      return;
    }
    if (data?.code === "INVALID_TOKEN") {
      setLoading(false);
      setFetchLoading(false);
      dispatch(LOGOUT_USER(false));
      navigate("../login");
      setTimeout(() => {
        toast.error(
          "You have been logged out. This may have been caused by using more than one device or browser",
          {
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }, 5000);

      setResult([]);
      return;
    } else {
      setLoading(false);
      setFetchLoading(false);
      toast.error(data?.message, {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  // call API on page load
  const getData = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);

    const response = await fetch(
      url,
      method === "GET"
        ? {
            headers: {
              "Content-Type": data === undefined ? {} : "application/json",
              Authorization: auth ? `Bearer ${token}` : {},
            },
            method,
            Authorization: auth ? `Bearer ${token}` : {},
          }
        : {
            headers: {
              "Content-Type": data === undefined ? {} : "application/json",
              Authorization: auth ? `Bearer ${token}` : {},
            },
            body: JSON.stringify(data === undefined ? {} : data),
            method,
            Authorization: auth ? `Bearer ${token}` : {},
          }
    );

    try {
      const data = await response?.json();
      checkAPIResponse(data);
    } catch (err) {
      setLoading(false);
      toast.error("Something went wrong. Please try again later", {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, [data, method, url]);

  useEffect(() => {
    if (method) {
      getData();
    }
  }, [data, method]);

  // call API on click
  const fetchFuntion = async (arg) => {
    const { url, method, data, auth } = arg ?? {};
    setFetchLoading(true);

    let output;

    const response = await fetch(
      url,
      method === "GET"
        ? {
            headers: {
              "Content-Type": data === undefined ? {} : "application/json",
              Authorization: auth ? `Bearer ${token}` : {},
            },

            method,
          }
        : {
            headers: {
              "Content-Type": data === undefined ? {} : "application/json",
              Authorization: auth ? `Bearer ${token}` : {},
            },
            body: JSON.stringify(data === undefined ? {} : data),
            method,
          }
    );
    try {
      const data = await response?.json();

      checkAPIResponse(data);
      output = data;
    } catch (err) {
      setFetchLoading(false);
      toast.error("Something went wrong. Please try again later", {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    return output;
  };

  return { result, loading, fetchFuntion, fetchLoading };
}
